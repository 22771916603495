<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <router-link
        class="tabe__btn-action tabe__btn-action--map"
        :to="`/scrims2/map/${matchId}`"
        :is="data.hasInferences ? 'router-link' : 'span'"
        :class="{ disabled: !data.hasInferences }"
      >
        2D Map
      </router-link>
    </template>
    <template #loading>
      <span class="tabe__btn-action tabe__btn-action--map disabled" :to="`/scrims2/map/${matchId}`">
        <MiniSpinner class="mr-2" />
        2D Map
      </span>
    </template>
  </ApiLoadingController>
</template>

<script>
import { hasMatchInterferences } from '@/api/val-scrims'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import MiniSpinner from '@/components/generic/MiniSpinner.vue'

export default {
  name: 'ScrimButtons',
  components: { MiniSpinner, ApiLoadingController },
  props: {
    matchId: String,
  },
  computed: {
    params() {
      return {
        matchId: this.matchId,
      }
    },
  },
  methods: {
    async fetch({ matchId }, config) {
      const hasInferences = await hasMatchInterferences(matchId, config)
      return {
        hasInferences: hasInferences,
      }
    },
  },
}
</script>

<style scoped></style>
