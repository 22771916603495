<template>
  <BTable ref="table" dark main-table hover striped v-bind="$attrs" v-on="$listeners">
    <template #table-busy>
      <Loading />
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </BTable>
</template>

<script>
import { BTable } from 'bootstrap-vue'

import Loading from './Loading.vue'

export default {
  name: 'AppTable',
  components: {
    Loading,
    BTable,
  },
}
</script>

<style lang="scss" scoped>
:deep(td),
:deep(th) {
  text-align: center;
}

:deep(td) {
  vertical-align: middle;
}

/* Busy table styling */
:deep(table.b-table[aria-busy='true']) {
  opacity: 0.6;
}
</style>
