<template>
  <div>
    <template v-if="(!loadingValue || lazy) && !errorValue">
      <slot v-bind="context">
        <component :is="childComponent" v-bind="childAttributes" v-on="childListeners" />
      </slot>
    </template>

    <template v-if="loadingValue">
      <slot name="loading" v-bind="context">
        <Loading />
      </slot>
    </template>

    <template v-else-if="errorValue">
      <slot name="error" v-bind="context">
        <ErrorAlert dismissible @dismissed="retry">
          <template #default>
            {{ errorValue }}
          </template>
          <template #dismiss>
            <BIconArrowRepeat title="Retry" />
          </template>
        </ErrorAlert>

        <slot name="after-error" v-bind="context" />
      </slot>
    </template>
  </div>
</template>

<script>
import { BIconArrowRepeat } from 'bootstrap-vue'
import px from 'vue-types'

import ErrorAlert from '../generic/ErrorAlert.vue'
import Loading from '../generic/Loading.vue'
import { pxNullable } from '../Map/types.js'

export default {
  name: 'LoadingController',
  components: { BIconArrowRepeat, Loading, ErrorAlert },
  props: {
    child: pxNullable(px.object).def(null),
    error: pxNullable(px.string).def(null),
    loading: pxNullable(px.bool.def(false)).def(null),
    lazy: pxNullable(px.bool.def(false)).def(null),
  },
  data: () => ({
    localError: null,
    localLoading: false,
    retryTrigger: 0,
  }),
  computed: {
    childAttributes() {
      return Object.freeze({
        error: this.errorValue,
        loading: this.loadingValue,
        retryTrigger: this.retryTrigger,
        ...this.$attrs,
      })
    },
    childComponent() {
      return this.child || 'div'
    },
    childListeners() {
      return Object.freeze({
        ...this.$listeners,
        'update:error': this.updateError,
        'update:loading': this.updateLoading,
      })
    },
    context() {
      return Object.freeze({
        attributes: this.childAttributes,
        listeners: this.childListeners,
      })
    },
    errorValue: {
      get() {
        return this.error != null ? this.error : this.localError
      },
      set(value) {
        this.localError = value
        this.$emit('update:error', value)
      },
    },
    loadingValue: {
      get() {
        return this.loading || this.localLoading
      },
      set(value) {
        this.localLoading = value
        this.$emit('update:loading', value)
      },
    },
  },
  watch: {
    errorValue: {
      immediate: true,
      handler(value) {
        if (this.$el) {
          this.$el.dispatchEvent(new CustomEvent('error', { detail: value, bubbles: true }))
        }
      },
    },
    loadingValue: {
      immediate: true,
      handler(value) {
        if (this.$el) {
          this.$el.dispatchEvent(new CustomEvent('loading', { detail: value, bubbles: true }))
        }
      },
    },
  },
  methods: {
    retry() {
      this.retryTrigger++
      this.$emit('retry', this.retryTrigger)
    },
    updateError(value) {
      this.errorValue = value
    },
    updateLoading(value) {
      this.loadingValue = value
    },
  },
}
</script>

<style scoped></style>
