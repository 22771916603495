<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <VodStatusIndicator :status="data.status" :match_id="matchId" />
    </template>
    <template #loading>
      <Loading label="" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { getMatchRecording } from '@/api/val-scrims'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import Loading from '@/components/generic/Loading.vue'
import VodStatusIndicator from '@/components/UI/VodStatusIndicator.vue'

export default {
  name: 'ScrimVodStatusIndicator',
  components: { VodStatusIndicator, ApiLoadingController, Loading },
  props: {
    matchId: String,
  },
  computed: {
    params() {
      return {
        matchId: this.matchId,
      }
    },
  },
  methods: {
    async fetch({ matchId }, config) {
      let state = null
      try {
        const matchRecording = await getMatchRecording(matchId, config)
        state = matchRecording?.state
      } catch (e) {
        console.warn('cannot find scrim', e)
      }

      return {
        status: this.mapVodStatus(state),
      }
    },
    mapVodStatus(state) {
      switch (state) {
        case 'processing':
          return 'executing'
        case 'pending':
          return 'provisioning'
        case 'processed':
          return 'succeeded'
        default:
          return 'failed'
      }
    },
  },
}
</script>

<style scoped></style>
