<template>
  <div>
    <PageTitle>Scrims</PageTitle>
    <ApiLoadingController ref="loadingController" :fetch="getScrims">
      <template #default="{ data }">
        <Scrim2Table :items="data" :sort-by="'created_at'" :sort-desc="true" @deleteScrim="deleteScrim" />
      </template>
    </ApiLoadingController>
  </div>
</template>

<script>
import Scrim2Table from '@/components/scrim2/Scrim2Table.vue'

import { getMatches, deleteMatch } from '../../api/val-scrims.js'
import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import PageTitle from '../../components/generic/PageTitle.vue'
export default {
  name: 'ScrimList',
  components: { ApiLoadingController, PageTitle, Scrim2Table },
  methods: {
    async getScrims() {
      const { items: scrims } = await getMatches()
      return Object.freeze(
        scrims.map(scrim =>
          Object.freeze({
            ...scrim,
            created_at: new Date(scrim.created_at * 1000),
            teams: [
              {
                name: 'Ally',
                team_side: 'n/a side',
                rounds_won: scrim.ally_score,
                rouns_played: scrim.total_rounds,
                rounds_first_half_won: scrim.ally_score_first_half,
                rounds_second_half_won: scrim.ally_score_second_half,
                rounds_overtime_won: scrim.ally_score_overtime,
                is_self_team: true,
                id: 'self',
                win: scrim.ally_score > scrim.enemy_score,
                agent_ids: scrim.ally_players?.map(player => player.agent_id),
              },
              {
                name: 'Enemy',
                team_side: 'n/a side',
                rounds_won: scrim.enemy_score,
                rouns_played: scrim.total_rounds,
                rounds_first_half_won: scrim.enemy_score_first_half,
                rounds_second_half_won: scrim.enemy_score_second_half,
                rounds_overtime_won: scrim.enemy_score_overtime,
                is_self_team: false,
                id: 'enemy',
                win: scrim.ally_score < scrim.enemy_score,
                agent_ids: scrim.enemy_players?.map(player => player.agent_id),
              },
            ],
          })
        )
      )
    },
    async deleteScrim(scrim) {
      await deleteMatch({ id: scrim.id })
      this.$refs.loadingController.retry()
    },
  },
}
</script>

<style scoped></style>
