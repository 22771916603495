import { render, staticRenderFns } from "./ScrimVodStatusIndicator.vue?vue&type=template&id=28ceb23b&scoped=true&"
import script from "./ScrimVodStatusIndicator.vue?vue&type=script&lang=js&"
export * from "./ScrimVodStatusIndicator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ceb23b",
  null
  
)

export default component.exports